import React from "react"
import { Link, graphql } from "gatsby"
import SEO from "../components/seo"
import "../styles/theme.scss"
import Logo from "../images/logo.kdc.svg"
import Tangerine from "../images/logo.tangerine-full.svg"
import Carousel from "../components/carousel";
import ArticleTile from "../components/article-tile";
import Callout from "../components/callout";
import Social from "../components/social";
import Layout from "../layouts/layout";

const Home = ({ data, location }) => {
    let global = data.contentfulWebsite;
    return (
        <Layout pathname={location.pathname}>
            <SEO metaTitle={global.title} metaDescription={global.description} author={global.author} shareImage={global.shareImage.file.url} />
            <div className="o-wrapper o-content o-container">
                <div className="o-content-container">
                    <h1>
                        <img className="c-logo" src={Logo} alt={global.title}></img>
                    </h1>
                    <h2 className="u-text-center c-tagline">{global.tagline}</h2>
                </div>
                <div className="o-flex o-flex--quarter o-flex--align-start o-content-spacer o-content-spacer--large">
                    <div className="o-flex__col-3">
                        <Carousel episodes={data.allContentfulEpisode.nodes}></Carousel>
                    </div>
                    <div className="u-text-center">
                        <hr className="u-hide u-hide--desktop" />
                        <a href="https://www.tangerine.ca/" target="_blank">
                            <img className="c-logo c-logo--sidebar" src={Tangerine} alt={data.contentfulHomepage.featuredArticlesTitle}></img>
                        </a>
                        <div className="o-content-spacer o-content-spacer--small o-content-container">
                            {data.allContentfulArticle.nodes.map((article, index) => {
                                return (
                                    <Link key={index} className="c-article-tile" to={`/article/${article.slug}`}>
                                        <ArticleTile article={article} />
                                    </Link>
                                )
                            })}
                            <Link to="/blog/all" className="c-button">{global.articleCtaText}</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="o-content-container">
                <Callout callout={data.contentfulHomepage.cards[0]} />
                { global.showSocialPosts ? (
                    <div className="o-wrapper o-content o-container o-content-container">
                        <Social title={global.socialPostsTitle} posts={data.allContentfulSocialPost.nodes} />
                    </div>
                ) : ''}
                {data.contentfulHomepage.cards.map((callout, index) => (
                    index > 0 ? <Callout key={index} callout={callout} /> : ''
                ))}
            </div>
        </Layout>
    );
};

export default Home

export const pageQuery = graphql`
{
    allContentfulEpisode(filter: {series: {title: {regex: "/Kneading Dough Canada/"}}}, limit: 6, sort: {fields: date, order: DESC}) {
        nodes {
            id
            name
            description {
                raw
            }
            date(formatString: "MMMM d, Y")
            youTubeLink
            thumbnail {
                resize(width: 486, height: 277) {
                  src
                }
            }
        }
    }
    contentfulWebsite(title: {eq: "Kneading Dough Canada"}) {
        author
        description
        title
        tagline
        socialPostsTitle
        articleCtaText
        showSocialPosts
        shareImage {
            file {
                url
            }
        }
    }
    allContentfulSocialPost(limit: 4, sort: {fields: updatedAt, order: DESC}) {
        nodes {
            link
            altText
            image {
                resize(width: 350, height: 350) {
                    src
                }
            }
        }
    }
    allContentfulArticle(limit: 2, sort: {fields: publishDate, order: DESC}) {
        nodes {
            title
            slug
            excerpt
            featuredImage {
                resize(width: 500, height: 300) {
                    src
                }
            }
            content {
                raw
            }
        }
    }
    contentfulHomepage(website: {title: {eq: "Kneading Dough Canada"}}) {
        featuredArticlesTitle
        cards {
            buttonText
            url
            title
            disclaimer {
                raw
            }
            subtitle
        }
    }
}
`;
