import React from "react"

export default class Social extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <>
                <div className="h2 u-text-center">{this.props.title}</div>
                <div className="o-flex o-flex--quarter o-flex--center">
                    {this.props.posts.map((post, index) => {
                        return (
                            <div className="u-text-center">
                                <a key={index} href={post.link} rel="noreferrer" target="_blank">
                                    <img alt={post.altText} className="u-img-border" src={post.image.resize.src} />
                                </a>
                            </div>
                        )
                    })}
                </div>
            </>
        );
    }
};