import React from "react"
import { Link } from "gatsby"

export default class ArticleTile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="u-text-center o-content-container o-content-container--small">
                {this.props.article.featuredImage ? <img alt={this.props.article.title} className={`u-img-border ${this.props.full ? 'c-article-image' : ''}`} src={this.props.article.featuredImage.resize.src} /> : ''}
                <div className="h4">{this.props.article.title}</div>
                {this.props.article.excerpt ? <div>{this.props.article.excerpt}</div> : ''}
                {this.props.readMore ? (
                    <div>
                        <Link to={`/article/${this.props.article.slug}`} className="c-button">{this.props.readMore}</Link>
                    </div>
                ) : ''}
            </div>
        );
    }
};