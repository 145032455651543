import React from "react"
import Tangerine from "../images/logo.tangerine-full.svg"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

export default class Callout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="u-background-grey">
                <div className="o-wrapper o-content o-container o-content-container">
                    <div className="o-flex o-flex--quarter o-flex--divider">
                        <a href="https://www.tangerine.ca/" target="_blank">
                            <img className="c-logo c-logo--sidebar" src={Tangerine} alt="Tangerine"></img>
                        </a>
                        <div className="o-flex__col-3 o-content-container">
                            <div className="h3">{this.props.callout.title}</div>
                            <div>
                                {this.props.callout.subtitle}
                            </div>
                            <div>
                                <a href={this.props.callout.url} rel="noreferrer" target="_blank" className="c-button">{this.props.callout.buttonText}</a>
                            </div>
                            {this.props.callout.disclaimer ? (
                                <div className="u-text-small u-text-uppercase">
                                    {documentToReactComponents(JSON.parse(this.props.callout.disclaimer.raw))}
                                </div>
                            ) : ''}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};