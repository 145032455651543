import React from "react"
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Thumbnail from "../components/thumbnail";

export default class Carousel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activeTile: 0,
            episodeNumber: this.props.episodes.length.toString(),
            youTubeId: this.props.episodes[0].youTubeLink.substring(this.props.episodes[0].youTubeLink.lastIndexOf('/') + 1)
        };

        this.changeActiveTile = this.changeActiveTile.bind(this);
    }

    changeActiveTile(index) {
        this.setState({ activeTile: index });
        this.setState({ episodeNumber: (this.props.episodes.length - index).toString() });
        this.setState({ youTubeId: this.props.episodes[index].youTubeLink.substring(this.props.episodes[index].youTubeLink.lastIndexOf('/') + 1) });

        document.querySelector("main").scrollIntoView();
    }

    render() {
        return (
            <>
                <div className="js-video"></div>
                <div className="c-video">
                    <iframe title="carousel-video" className="c-video__frame u-img-border" src={`https://www.youtube.com/embed/${this.state.youTubeId}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                </div>
                <div className="c-carousel o-flex o-flex--quarter o-content-spacer">
                    <div className="o-child-spacer o-child-spacer o-child-spacer--small c-carousel__bullets">
                        {this.props.episodes.map((episode, index) => {
                            return <a href="javascript:void(0);" onClick={() => this.changeActiveTile(index)} className={`c-carousel__bullet ${index === this.state.activeTile ? 'c-carousel__bullet--active' : ''}`} key={episode.id}><span className="u-hide">{index}</span></a>;
                        })}
                    </div>
                    <div className="o-flex__col-3 o-child-spacer c-carousel__description">
                        {this.props.season ? <div className="h1">{this.state.episodeNumber.padStart(2, '0')}</div> : ''}
                        <div>
                            {documentToReactComponents(JSON.parse(this.props.episodes[this.state.activeTile].description.raw))}
                        </div>
                    </div>
                </div>
                <div className="o-content-spacer o-flex o-flex--third o-flex--align-start">
                    {this.props.episodes.map((episode, index) => {
                        return <Thumbnail key={index} index={index} episode={episode} activeTile={this.state.activeTile} changeActiveTile={this.changeActiveTile}></Thumbnail>
                    })}
                </div>
            </>
        );
    }
};
