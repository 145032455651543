import React from "react"

export default class Thumbnail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
        };
    }

    render() {
        return (
            <div className="u-text-center">
                <a href="javascript:void(0);" className={`c-thumbnail u-img-border ${this.props.index === this.props.activeTile ? 'c-thumbnail--active' : ''}`} onClick={() => this.props.changeActiveTile(this.props.index)}>
                    <img className="c-thumbnail__image" alt={this.props.episode.name} src={this.props.episode.thumbnail.resize.src} />
                </a>
                <h4>{this.props.episode.name}</h4>
            </div>
        );
    }
};